import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import App from './App';
import reportWebVitals from './reportWebVitals';
import { DataProvider } from './context/DataContext';
import ErrorBoundary from './error/ErrorBoundary';

const root = ReactDOM.createRoot(document.getElementById('root'));

const App = React.lazy(() => import('./App'));
root.render(

  <DataProvider initialData='InitialData'>
  <React.Suspense fallback={<div>Loading...</div>}>
  <ErrorBoundary>


      <React.StrictMode>
        <App />
      </React.StrictMode>
      
  </ErrorBoundary>
  </React.Suspense>
  
  </DataProvider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

/*
const sendToAnalytics = (metric) => {

  const body = JSON.stringify(metric);
  const url = 'http://localhost:3001/reactPerformance';

  // Use `navigator.sendBeacon()` if available, falling back to `fetch()`
  if (navigator.sendBeacon) {
    navigator.sendBeacon(url, body);
  } else {
    fetch(url, { body, method: 'POST', keepalive: true });
  }
}
*/
//reportWebVitals(console.log);
